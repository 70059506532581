import * as React from 'react';
import { AppBar, AppContent, Spinner } from '@components';
import classnames from 'classnames';
import withServiceWorker from 'grab-service-worker';
import { Layout } from 'grab-ui';
import { Route, Switch } from 'react-router-dom';
import styles from './App.less';
const LoginPage = React.lazy(() => import('src/routes/login'));
const App = () => (React.createElement(React.Suspense, { fallback: React.createElement(Spinner, null) },
    React.createElement(Switch, null,
        React.createElement(Route, { exact: true, key: "login", path: "/login", component: () => React.createElement(LoginPage, null) }),
        React.createElement(Route, { render: () => (React.createElement(Layout, { className: classnames(styles.layout, {
                    'ant-layout-has-sider': true,
                }) },
                React.createElement(Layout, null,
                    React.createElement(AppBar, null),
                    React.createElement(AppContent, null)))) }))));
App.displayName = 'App';
export default withServiceWorker()(App);
