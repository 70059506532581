/* eslint-disable react/display-name */

import React from 'react';

import { Spinner, ProtectedRoute } from '@components';
import { Icon } from 'grab-ui';
import { Switch } from 'react-router-dom';

const HomePage = React.lazy(() => import('src/routes/home'));
const NotFoundPage = React.lazy(() => import('src/routes/not-found'));
const DriverSearchPage = React.lazy(() => import('src/routes/driver/search'));
const DriverCreatePage = React.lazy(() => import('src/routes/driver/create'));
const DriverUpdatePage = React.lazy(() => import('src/routes/driver/update'));

const renderRoutes = (routes) =>
  routes.map((route) => {
    if (route.children) {
      return renderRoutes(route.children);
    }
    return (
      <ProtectedRoute
        key={route.pathname}
        path={route.pathname}
        exact={route.exact}
        component={route.component}
      />
    );
  });

export const ROUTES = [
  {
    label: (
      <span>
        <Icon type="home" />
        <span>Home</span>
      </span>
    ),
    pathname: '/',
    exact: true,
    component: () => <HomePage />,
  },
  {
    label: (
      <span>
        <Icon type="grabicon-driver-service" />
        <span>Drivers</span>
      </span>
    ),
    children: [
      {
        label: (
          <span>
            <Icon type="search" />
            <span>Search</span>
          </span>
        ),
        pathname: '/drivers',
        exact: true,
        component: () => <DriverSearchPage />,
      },
      {
        label: (
          <span>
            <Icon type="plus" />
            <span>Create</span>
          </span>
        ),
        pathname: '/drivers/create',
        exact: true,
        component: () => <DriverCreatePage />,
      },
      {
        label: (
          <span>
            <Icon type="edit" />
            <span>Update</span>
          </span>
        ),
        pathname: '/drivers/update',
        exact: true,
        component: () => <DriverUpdatePage />,
      },
    ],
  },
];

const AppRoutes = () => (
  <React.Suspense fallback={<Spinner />}>
    <Switch>
      {renderRoutes(ROUTES)}
      <ProtectedRoute
        path="*"
        component={(props) => <NotFoundPage {...props} />}
      />
    </Switch>
  </React.Suspense>
);

AppRoutes.displayName = 'AppRoutes';

export default AppRoutes;
