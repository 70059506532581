import React from 'react';
import AppCard from '@components/landing-page/app-card/AppCard';
import { Col, Row } from 'grab-ui';
import styles from './Section.less';
const Section = ({ title, apps }) => {
    return (React.createElement("div", { className: styles.section },
        React.createElement(Row, { type: 'flex' },
            React.createElement(Col, { span: 18, offset: 3 },
                React.createElement("div", { className: styles.sectionTitle }, title),
                React.createElement(Row, { type: "flex", gutter: 16 }, apps.map((app, idx) => (React.createElement(Col, { key: `app-card-${idx}`, span: 6, className: styles.appRow },
                    React.createElement(AppCard, { image: app.image, title: app.title, description: app.description, link: app.link })))))))));
};
export default Section;
