import { ApolloLink } from 'apollo-link';
import { getToken, isAuthenticated } from 'grab-login/lib/helper';
import Raven from 'raven-js';

const authLink = new ApolloLink((operation, forward) => {
  const headers = {};

  if (!window.Grab.config.appId) {
    const err = new Error('Please configure `appId` in config.json.');
    Raven.captureException(err);
    throw err;
  }

  if (isAuthenticated()) {
    headers.Authorization = `${getToken()}`;
  }

  // add the authorization to the headers
  operation.setContext({
    headers,
  });

  return forward(operation);
});

export default authLink;
