export const PAGE_SECTIONS = [
    {
        title: 'Dispatch',
        apps: [
            {
                image: require('public/images/apps/cradle.png'),
                title: 'Cradle',
                description: 'Taxi Type Config Management Portal. Also contains some on-call tools for Dispatch team.',
                link: {
                    prd: 'https://cradle.grab.com/',
                    stg: 'https://cradle.stg-myteksi.com/',
                },
            },
            {
                image: require('public/images/apps/dexter.png'),
                title: 'Dexter',
                description: 'Allocation diagnostic portal',
                link: {
                    prd: 'https://dexter.grab.com/',
                    stg: 'https://dexter.stg-myteksi.com/',
                },
            },
        ],
    },
    {
        title: 'Pricing',
        apps: [
            {
                image: require('public/images/apps/marketwatch.png'),
                title: 'Marketwatch',
                description: 'Enables market simulations and provides observability, diagnostic, and anomaly detection.',
                link: {
                    prd: 'https://marketwatch.grab.com/',
                    stg: 'https://marketwatch.stg-myteksi.com/',
                },
            },
            {
                image: require('public/images/apps/gamma.png'),
                title: 'Gamma Econs',
                description: 'Portal to manage resources like pricing model, incentives, commissions, etc.',
                link: {
                    prd: 'https://gamma-econs.grab.com/fares',
                    stg: 'https://gamma-econs.stg-myteksi.com/fares',
                },
            },
            {
                image: require('public/images/apps/fare-debugger.png'),
                title: 'Fare Debugger (Now in Dexter)',
                description: 'Visualise, debug, and understand fare calculations. Also, modification or fare recalculation.',
                link: {
                    prd: 'https://dexter.grab.com/bookings/',
                    stg: 'https://dexter.stg-myteksi.com/bookings/',
                },
            },
        ],
    },
    {
        title: 'DAX',
        apps: [
            {
                title: 'Register',
                image: require('public/images/apps/register.png'),
                description: 'Portal for drivers to register themselves.',
                link: {
                    prd: 'https://register.grab.com/',
                    stg: 'https://register.stg-myteksi.com/',
                },
            },
            {
                title: 'Bolt',
                image: require('public/images/apps/bolt.png'),
                description: 'Portal for grab ops / partners to register drivers.',
                link: {
                    prd: 'https://bolt.grab.com/',
                    stg: 'https://bolt.stg-myteksi.com/',
                },
            },
            {
                title: 'Stellar',
                image: require('public/images/apps/stellar.png'),
                description: 'Portal for grab ops to review and approve driver documents and registers.',
                link: {
                    prd: 'https://stellar.grab.com/',
                    stg: 'https://stellar.stg-myteksi.com/',
                },
            },
            {
                title: 'Localization',
                image: require('public/images/apps/localization.png'),
                description: 'Portal to manage locales for backend services',
                link: {
                    prd: 'https://localization.grab.com/',
                    stg: 'https://localization.stg-myteksi.com/',
                },
            },
            {
                title: 'Gamma DAX',
                image: require('public/images/apps/gamma.png'),
                description: 'Portal to manage resouces like driver details, driver shifts, comission, incentives, etc.',
                link: {
                    prd: 'https://gamma-daxexp.grab.com/drivers',
                    stg: 'https://gamma-daxexp.stg-myteksi.com/drivers',
                },
            },
            {
                title: 'Unicorn DAX',
                image: require('public/images/apps/unicorn.png'),
                description: 'Portal to batch add driver attributes and anything related to DAX',
                link: {
                    prd: 'https://unicorn.grab.com/',
                    stg: 'https://unicorn.stg-myteksi.com/',
                },
            },
        ],
    },
];
