import React from 'react';
import Section from '@components/landing-page/section/Section';
import { Col, Row } from 'grab-ui';
import styles from './LandingPage.less';
import { PAGE_SECTIONS } from '../../config/apps';
const LandingPage = () => {
    return (React.createElement("div", { className: styles.landingPage },
        React.createElement(Row, null,
            React.createElement(Col, { span: 6, offset: 3 },
                React.createElement("h1", null, "Fulfilment Suite"))),
        PAGE_SECTIONS.map((section, idx) => (React.createElement(Section, { key: `section_${idx}`, title: section.title, apps: section.apps })))));
};
export default LandingPage;
