import React from 'react';
import { Card } from 'grab-ui';
import styles from './AppCard.less';
import { HOSTS } from '../../../config/hosts';
const { Meta } = Card;
const AppCard = ({ title, image, description, link }) => {
    const cardImg = React.createElement("img", { alt: "example", src: image, className: styles.coverImg });
    const getAppUrl = () => {
        const currUrl = window.location.href;
        let appLink;
        if (currUrl.includes(HOSTS.stg) || currUrl.includes(HOSTS.dev)) {
            appLink = (link === null || link === void 0 ? void 0 : link.stg) || '#';
        }
        else {
            appLink = (link === null || link === void 0 ? void 0 : link.prd) || '#';
        }
        return appLink;
    };
    return (React.createElement("div", { className: styles.cardWrapper },
        React.createElement("a", { href: getAppUrl() },
            React.createElement(Card, { className: styles.appCard, cover: cardImg, bodyStyle: { borderTop: '1px solid #E5E9F0' } },
                React.createElement(Meta, { title: title, description: description })))));
};
export default AppCard;
