import React from 'react';
import LandingPage from '@components/landing-page/LandingPage';
import Layout from 'grab-ui/dist/Layout';
import styles from './AppContent.less';
const { Content } = Layout;
const AppContent = () => (React.createElement(Content, { className: styles.content },
    React.createElement("div", { className: styles.page },
        React.createElement(LandingPage, null))));
AppContent.displayName = 'AppContent';
export default AppContent;
