import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';

import authLink from './links/authLink';

export default function createApolloClient() {
  const cache = new InMemoryCache();
  const httpLink = new HttpLink({ uri: window.Grab.config.uri });

  // Link composition
  const link = authLink.concat(httpLink);

  return new ApolloClient({
    link,
    cache,
    queryDeduplication: true,
    connectToDevTools: true,
  });
}
