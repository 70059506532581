import * as React from 'react';
import { ROUTES } from '@components/app-routes';
import { Layout, Menu } from 'grab-ui';
import { Link, withRouter } from 'react-router-dom';
import styles from './AppSider.less';
const { Item, SubMenu } = Menu;
const { Sider } = Layout;
const getOpenKeys = (pathname) => {
    const splits = pathname.split('/');
    return splits.length > 1 ? [splits[1]] : [pathname];
};
class AppSider extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            openKeys: getOpenKeys(this.props.location.pathname),
            selectedKeys: [this.props.location.pathname],
        };
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { location } = nextProps;
        this.setState({ selectedKeys: [location.pathname] });
    }
    render() {
        return (React.createElement(Sider, { className: styles.sider },
            React.createElement(Link, { className: styles.logoWrapper, to: '/' },
                React.createElement("img", { alt: 'Grab', className: styles.logo, src: require('public/images/brand.svg') })),
            React.createElement(Menu, { theme: "dark", mode: "inline", defaultOpenKeys: this.state.openKeys, selectedKeys: this.state.selectedKeys }, ROUTES.map((route) => route.children ? (React.createElement(SubMenu, { key: route.children[0].pathname.split('/')[1], title: route.label }, route.children.map((child) => (React.createElement(Item, { key: child.pathname, className: styles.menuItem },
                React.createElement(Link, { to: child.pathname }, child.label)))))) : (React.createElement(Item, { key: route.pathname },
                React.createElement(Link, { to: route.pathname }, route.label)))))));
    }
}
export default withRouter(AppSider);
